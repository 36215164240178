:root {
  --color-base: #FFFEFD;
  --color-base-alpha: #FFFEFDA0;
  --color-fig: #C0AEFF;
  --color-secondary: rgb(109, 110, 120);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-base: #14161E;
    --color-base-alpha: #14161EA0;
    --color-fig: #9C79FF;
    --color-secondary: rgb(179, 179, 179);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
}

#card {
  position: relative;
  margin: 15px 0;
  padding: 15px;
  background-color: var(--color-base);
  border-radius: 8px;
}

#link {
  padding-bottom: 12px;
}

button {
  width: 100%;
  background: #F2A967;
  border-radius: 8px;
  color: white;
  border: 0;
  padding: 20px 16px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

#overlay {
  background-color: var(--color-base-alpha);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--color-fig);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message {
  padding-top: 2px;
  color: var(--color-secondary);
  font-size: 12px;
  animation: 0.5s fadeIn 0.5s backwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
